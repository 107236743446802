
import jQuery from 'jquery'
import '../../assets/colorbox/css/colorbox.css'
import '../../assets/colorbox/js/colorbox'

(function ($) {
    $(document).ready(function () {
        $('a[data-lightbox]').map(function () {
            $(this).colorbox({
                loop: false,
                rel: $(this).attr('data-lightbox'),
                maxWidth: '95%',
                maxHeight: '95%',
                current: '{current} / {total}'
            });
        });
    });
})(jQuery);
