import { Controller } from '@hotwired/stimulus';
import Chart from 'chartjs-gauge';
import 'chartjs-plugin-datalabels';

export default class extends Controller {
    static values = {
        current: Number,
        steps: Array,
        colors: Array,
    };

    connect () {
        let width, height, gradient;
        function getGradient(ctx, chartArea, colors) {
            const chartWidth = chartArea.right - chartArea.left;
            const chartHeight = chartArea.bottom - chartArea.top;
            if (!gradient || width !== chartWidth || height !== chartHeight) {
                width = chartWidth;
                height = chartHeight;
                gradient = ctx.createLinearGradient(chartArea.left, chartArea.bottom, chartArea.right, chartArea.top);
                gradient.addColorStop(0, colors[1]);
                gradient.addColorStop(0.5, colors[2]);
                gradient.addColorStop(0.8, colors[3]);
            }

            return [colors[0], gradient];
        }

        new Chart(this.element, {
            type: 'gauge',
            data: {
                //labels: ['Success', 'Warning', 'Warning', 'Error'],
                datasets: [{
                    data: this.stepsValue,
                    value: this.currentValue,
                    // backgroundColor: this.colorsValue,
                    backgroundColor: (context) => {
                        const chart = context.chart;
                        const {ctx, chartArea} = chart;

                        if (!chartArea) {
                            // This case happens on initial chart load
                            return;
                        }
                        console.log(ctx, chartArea);
                        return getGradient(ctx, chartArea, this.colorsValue);
                    },
                    borderWidth: 2
                }]
            },
            options: {
                responsive: true,
                layout: {
                    padding: {
                        top: 20,
                        right: 30,
                    }
                },
                needle: {
                    // Needle circle radius as the percentage of the chart area width
                    radiusPercentage: 2,
                    // Needle width as the percentage of the chart area width
                    widthPercentage: 3.2,
                    // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
                    lengthPercentage: 80,
                    // The color of the needle
                    color: 'rgb(0, 152, 172)'
                },
                valueLabel: {
                    display: false,
                    // formatter: (v) => new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'EUR' }).format(Math.round(v)),
                    // backgroundColor: '#000',
                },
                plugins: {
                    datalabels: {
                        display: true,
                        // formatter: (v) => new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'EUR' }).format(Math.round(v)),
                        formatter: (v) => v <= this.stepsValue[0] ? new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'EUR' }).format(Math.round(v)) : null,
                        color: 'rgba(255, 255, 255, 1.0)',
                        backgroundColor: '#000',
                        borderWidth: 0,
                        borderRadius: 5,
                    }
                }
            },
        });
    }
}
