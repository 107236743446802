import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['amount', 'price', 'total'];


    initialize () {
        this.update = this.update.bind(this);
    }

    connect () {
        this.$amount = document.querySelector('input[name="installations"]');
        this.$price = document.querySelector('input[name="amount"]');

        if (!this.$amount || !this.$price) {
            return;
        }

        this.$amount.addEventListener('change', this.update);
        this.$price.addEventListener('change', this.update);

        this.update();
    }

    disconnect () {
        this.$amount?.removeEventListener('change', this.update);
        this.$price?.removeEventListener('change', this.update);
    }

    update () {
        const amount = Number.parseInt(this.$amount.value, 10) || 1;
        const price = Number.parseInt(this.$price.value, 10);

        this.amountTarget.innerText = amount;
        this.priceTarget.innerText = this.formatPrice(price);
        this.totalTarget.innerText = this.formatPrice(amount * price);
    }

    formatPrice(amount) {
        if (Number.isNaN(amount)) {
            return '?';
        }

        return amount;
    }
}
