import jQuery from 'jquery'
import '../../assets/jquery-ui/js/jquery-ui'

(function($) {
    $(document).ready(function() {
        $(document).accordion({
            active: false,
            heightStyle: 'content',
            header: 'div.toggler',
            collapsible: true
        });
        var activate = function(tog) {
            var tgs = $('div.toggler');
            tgs.removeClass('active');
            tog.addClass('active');
            tgs.next('div.accordion').attr('aria-hidden', 'true');
            tog.next('div.accordion').attr('aria-hidden', 'false');
        };
        $('div.toggler').focus(function() {
            $('div.toggler').attr('tabindex', 0);
            $(this).attr('tabindex', -1);
        }).blur(function() {
            $(this).attr('tabindex', 0);
        }).click(function() {
            activate($(this));
        }).keypress(function(event) {
            if (event.keyCode == 13) activate($(this));
        });
    });
})(jQuery);
