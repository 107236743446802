import './styles/app.scss'
import "./images/isotope-logo.png";

import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';

window.Stimulus = Application.start();
window.Stimulus.debug = process.env.NODE_ENV === 'development';
const context = require.context('./controllers', true, /\.js$/);
window.Stimulus.load(definitionsFromContext(context));


import './scripts/_colorbox'
import './scripts/_accordion'
import jQuery from 'jquery'
global.jQuery = jQuery
window.jQuery = jQuery

try {
    import('./scripts/script.min')
} catch (e) {
    // Ignore
}

// import './jquery-scrolltofixed-min'

(function ($) {
    $(document).ready(function () {
        document.documentElement.className = document.documentElement.className.replace(/\bno-js\b/g, '') + ' js';
        /* prepend menu icon */
        $('#nav').prepend('<div id="nav-icon"><span class="invisible">Navigation</span></div>');

        /* toggle nav */
        $('#nav-icon').on('click', function () {
            $('#nav .level_1').slideToggle();
            $(this).toggleClass('active');
        });
    });
})(jQuery);
